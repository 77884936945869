<style lang="less" scoped>
  .project-page {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .toolbox {
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
  .query-form {
    margin-top: 10px;
  }
  .table {
    margin-top: 10px;
    height: 0;
    flex: 1;
  }
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<style>
  .project-page .ivu-tag {
    border: none !important;
  }
</style>

<template>
  <div class="project-page">
    <div class="toolbox">
      <div class="title">
        <fm-title title-text="绩效项目" :note-text="'共' + page.total + '项'"></fm-title>
        <Row :gutter="10">
          <i-col><Button :loading="loading.load" icon="md-refresh-circle" @click="loadData">刷新</Button></i-col>
          <template v-if="mode !== 'choose'">
            <i-col><Button v-if="$authFunsProxyNoRoute['performance.project-typeGet']" @click="status.typePage = true"><i style="line-height: 1.5; margin-right: 4px;" class="iconfont icon-lanmupeizhi"></i>项目类型管理</Button></i-col>
            <!-- <i-col><Button v-if="$authFunsProxyNoRoute['performance.project-singleGet']" @click="openSingle"><i style="line-height: 1.5; margin-right: 4px;" class="iconfont icon-kaoheguanli"></i>单项奖励配置</Button></i-col> -->
            <i-col>
              <Poptip v-if="$authFunsProxyNoRoute['performance.project-manage']" confirm @on-ok="dataSync" title="确定同步吗？">
                <Button :loading="loading.sync" type="warning" icon="md-sync">数据同步</Button>
              </Poptip>
            </i-col>
            <i-col><Button v-if="$authFunsProxyNoRoute['performance.project-manage']" type="primary" @click="addClick" icon="md-add-circle">新增绩效项目</Button></i-col>
          </template>
        </Row>
      </div>
      <Row class="query-form" align="middle" :gutter="10">
        <i-col>
          <i-input placeholder="请输入搜索内容" clearable search @on-blur="onSearch" @on-search="onSearch" @on-clear="onSearch" v-model="query.searchKey">
            <Select v-model="query.searchType" slot="prepend" style="width: 80px">
              <Option value="name">名称</Option>
              <Option value="hisCode">his编码</Option>
            </Select>
          </i-input>
        </i-col>
        <i-col>项目类型: </i-col>
        <i-col>
          <TypeSelect @change="onSearch" v-model="query.pfmProjectTypeId" />
        </i-col>
        <!-- <i-col>单项奖励: </i-col>
        <i-col>
          <Dropdown trigger="click" @on-click="name => onSearch({isSinglePfm: name})">
            <Button type="primary" v-if="query.isSinglePfm === 'yes'">是<Icon type="ios-arrow-down"></Icon></Button>
            <Button type="warning" v-else-if="query.isSinglePfm === 'no'">否<Icon type="ios-arrow-down"></Icon></Button>
            <Button type="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Button>
            <DropdownMenu slot="list">
              <DropdownItem :selected="query.isSinglePfm === 'all'" name="all">全部</DropdownItem>
              <DropdownItem :selected="query.isSinglePfm === 'yes'" name="yes">是</DropdownItem>
              <DropdownItem :selected="query.isSinglePfm === 'no'" name="no">否</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </i-col> -->
        <i-col>普通工作量绩效: </i-col>
        <i-col>
          <Dropdown trigger="click" @on-click="name => onSearch({isOutUnit: name})">
            <Button type="primary" v-if="query.isOutUnit === 'yes'">不纳入<Icon type="ios-arrow-down"></Icon></Button>
            <Button type="warning" v-else-if="query.isOutUnit === 'no'">纳入<Icon type="ios-arrow-down"></Icon></Button>
            <Button type="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Button>
            <DropdownMenu slot="list">
              <DropdownItem :selected="query.isOutUnit === 'all'" name="all">全部</DropdownItem>
              <DropdownItem :selected="query.isOutUnit === 'yes'" name="yes">不纳入</DropdownItem>
              <DropdownItem :selected="query.isOutUnit === 'no'" name="no">纳入</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </i-col>
      </Row>
    </div>
    <div class="table" ref="table">
      <Table :loading="loading.load" v-if="tableHeight !== null" :height="tableHeight" :columns="columns" :data="dataList">
        <template slot-scope="{ row }" slot="choose-action">
          <Button type="text" size="small" @click="itemChoose(row)">选取</Button>
        </template>
        <template slot-scope="{ row }" slot="action">
          <!-- <Button type="text" size="small" style="margin-right: 5px" @click="itemSingle(row)">单项奖励</Button> -->
          <Button type="text" size="small" style="margin-right: 5px" @click="itemEdit(row)">修改</Button>
          <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button icon="md-trash" size="small" type="text">删除</Button>
          </Poptip>
        </template>
        <template slot="action-single">
          <!-- <Button type="text" size="small" @click="itemSingle(row)">单项奖励</Button> -->
        </template>
      </Table>
    </div>
    <div style="padding: 10px;background-color: #FFF;">
      <Page @on-change="onPageChange" :total="page.total" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer title="绩效项目" draggable :mask-closable="false" width="40" placement="right" closable v-model="status.typePage">
      <TypePage v-if="status.typePage" style="height: 100%;" />
    </Drawer>
    <!-- <Drawer :title="(formData.id ? formData.name : '') + ' - 单项奖励配置'" draggable :mask-closable="false" width="40" placement="right" closable v-model="status.singlePage">
      <SinglePage :pfmProjectId="formData.id" v-if="status.singlePage" style="height: 100%;" />
    </Drawer> -->
    <Drawer :title="(formData.id ? '修改' : '新增') + '绩效项目'" draggable :mask-closable="false" width="30" placement="right" closable v-model="status.form">
      <fm-form label-alone label-align="left">
        <fm-form-item label="名称">
          <fm-input-new placeholder="请输入名称" v-model="formData.name" />
        </fm-form-item>
        <fm-form-item label="his编码">
          <fm-input-new placeholder="请输入his编码" v-model="formData.hisCode" />
        </fm-form-item>
        <fm-form-item label="项目类型">
          <TypeSelect v-model="formData.pfmProjectTypeId" />
        </fm-form-item>
         <!-- <fm-form-item label="是否进行单项奖励">
          <fm-switch size="small" v-model="formData.isSinglePfm">
          </fm-switch>
        </fm-form-item> -->
        <fm-form-item label="大循环绩效">
          <fm-switch size="small" v-model="formData.isOutUnit">
            <span slot="on">不纳入</span>
            <span slot="off">纳入</span>
          </fm-switch>
        </fm-form-item>
        <fm-form-item label="单次工作量">
            <fm-input-number v-model="formData.defaultWorkLoad"></fm-input-number>
        </fm-form-item>
        <fm-form-item label="单价">
            <fm-input-number v-model="formData.defaultIncome"></fm-input-number>
        </fm-form-item>
        <fm-form-item label="">
          <div style="display: flex;">
            <Button style="flex: 1;" @click="status.form = false" type="default">取消</Button>
            <Button style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'
import TypePage from './type.vue'
// import SinglePage from './single.vue'
import TypeSelect from '../../components/select/ProjectType.vue'

function getDefaultQuery () {
  return {
    searchKey: null,
    searchType: 'name',
    pfmProjectTypeId: null,
    isSinglePfm: 'all',
    isOutUnit: 'all'
  }
}

function getDefaultFormData () {
  return {
    id: null,
    name: null,
    hisCode: null,
    pfmProjectTypeId: null,
    isSinglePfm: null,
    isOutUnit: null,
    defaultWorkLoad: null,
    defaultIncome: null
  }
}

export default {
  name: 'ProjectPage',
  components: { TypePage, TypeSelect },
  props: {
    mode: { type: String, default: null }
  },
  data () {
    return {
      dataList: [],
      query: getDefaultQuery(),
      formData: getDefaultFormData(),
      status: {
        typePage: false,
        singlePage: false,
        form: false
      },
      page: {
        size: 10,
        num: 1,
        total: 0
      },
      loading: {
        load: false,
        submit: false,
        sync: false
      },
      tableHeight: null
    }
  },
  computed: {
    columns () {
      const columns = [
        { title: '名称', key: 'name', minWidth: 200, ellipsis: true, tooltip: true, fixed: 'left' },
        { title: 'his编码', key: 'hisCode', minWidth: 100 },
        { title: '项目类型', key: 'pfmProjectTypeName', minWidth: 100 },
        // {
        //   title: '单项奖励', key: 'isSinglePfm', width: 100, render (h, { row }) {
        //     return h('Tag', {
        //       props: {
        //         color: row.isSinglePfm ? 'blue' : 'orange'
        //       }
        //     }, row.isSinglePfm ? '是' : '否')
        //   }
        // },
        {
          title: '大循环绩效', key: 'isOutUnit', width: 110, render (h, { row }) {
            return h('Tag', {
              props: {
                color: row.isOutUnit ? 'orange' : 'blue'
              }
            }, row.isOutUnit ? '不纳入' : '纳入')
          }
        },
        { title: '单次工作量', key: 'defaultWorkLoad', minWidth: 110 },
        { title: '单价', key: 'defaultIncome', minWidth: 100 }
      ]

      if (this.mode === 'choose') {
        return [
          ...columns,
          {
            title: '操作', key: 'choose-action', fixed: 'right',
            slot: 'choose-action', width: 110
          }
        ]
      } else if (this.$authFunsProxyNoRoute['performance.project-manage']) {
        return [
          ...columns,
          {
            title: '操作', key: 'action', fixed: 'right',
            slot: 'action', width: 180
          }
        ]
      } else if (this.$authFunsProxyNoRoute['performance.project-singleGet']) {
        return [
          ...columns,
          {
            title: '操作', key: 'action-single', fixed: 'right',
            slot: 'action-single', width: 110
          }
        ]
      }

      return columns
    }
  },
  methods: {
    onSearch (query) {
      if (query) {
        Object.assign(this.query, query)
      }
      this.page.num = 1
      this.loadData()
    },
    itemChoose (item) {
      this.$emit('choose', JSON.parse(JSON.stringify(item)))
    },
    onPageChange (num) {
      this.page.num = num
      this.loadData()
    },
    openSingle () {
      this.$router.push({
        name: 'performance.project.single', query: {
          back: true
        }
      })
    },
    itemSingle (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.singlePage = true
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.form = true
    },
    async itemDel (item) {
      const confirm = await this.$dialog.confirm('确定删除吗？')
      if (confirm) {
        await request('/performance/pfm_project/' + item.id, 'delete')
        this.$Message.success('已删除')
        this.loadData()
      }
    },
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_project/sync', 'post')
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    async loadData () {
      if (this.$authFunsProxyNoRoute['performance.project-get']) {
        const params = {
          pageNum: this.page.num,
          pageSize: this.page.size
        }

        if (this.query.searchKey && this.query.searchType) {
          params[this.query.searchType] = this.query.searchKey
        }
        if (this.query.pfmProjectTypeId) {
          params.pfmProjectTypeId = this.query.pfmProjectTypeId
        }
        // if (this.query.isSinglePfm !== 'all') {
        //   params.isSinglePfm = this.query.isSinglePfm === 'yes' ? 1 : 0
        // }
        if (this.query.isOutUnit !== 'all') {
          params.isOutUnit = this.query.isOutUnit === 'yes' ? 1 : 0
        }

        this.loading.load = true
        const res = await request('/performance/pfm_project', 'get', params)
        this.page.total = res.total
        this.dataList = res.data
        // this.dataList = await this.$store.dispatch('loadPfmProjectList', true)
        this.loading.load = false
      }
    },
    resize () {
      this.tableHeight = this.$refs.table.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    },
    async submit () {
      this.loading.submit = true
      try {
        if (this.formData.id) {
          await request('/performance/pfm_project/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/performance/pfm_project', 'post', this.formData)
          this.page.num = 1
        }
        this.status.form = false
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.loading.submit = false
      }
    }
  },
  mounted() {
    this.loadData()
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>