<style lang="less" scoped>
  @color-primary: #1cb5e0;
  @color-primary-shadow: rgba(28, 181, 224, .15);
  .current-value, .placeholder {
    cursor: pointer;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    padding: 0 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      border-color: @color-primary;
    }
    &:active {
      box-shadow: 0 0 0px 3px @color-primary-shadow;
    }
  }
  .placeholder {
    color: #999;
  }
  .fmico-error-solid {
    margin-left: 8px;
    opacity: 0;
    transition: all .3s;
  }
  .current-value:hover .fmico-error-solid {
    opacity: 1;
  }
</style>

<style>
  .project-type-select .ivu-tooltip, .project-type-select .ivu-tooltip-rel {
    display: block;
  }
</style>

<template>
  <div class="project-type-select">
    <Tooltip v-if="current">
      <div class="current-value" @click="status.drawer = true">
        <span>{{current.name}} - {{current.hisCode}}</span>
        <i v-if="clearable !== false" class="fmico fmico-error-solid" @click.stop.self="clearData"></i>
      </div>
      <div slot="content">
        <div>类型: {{current.type}}</div>
        <div>循环大绩效: {{current.isOutUnit ? '不纳入' : '纳入'}}</div>
      </div>
    </Tooltip>
    <template v-else>
      <div class="placeholder" @click="status.drawer = true">{{placeholder}}</div>
    </template>
    <Drawer :transfer="false" :title="placeholder" width="30" placement="right" closable v-model="status.drawer">
      <ProjectType style="height: 100%;" v-if="status.drawer" picker @pick="onPick" />
    </Drawer>
  </div>
</template>

<script>
import ProjectType from '../../views/project/type.vue'
export default {
  components: { ProjectType },
  props: {
    value: { type: Number, default: null },
    placeholder: { type: String, default: '请选择绩效项目类型' },
    clearable: { type: Boolean, default: true }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      data: this.value,
      status: {
        drawer: false
      }
    }
  },
  watch: {
    value (value) {
      if (value !== this.data) {
        this.data = value
      }
    }
  },
  computed: {
    current () {
      if (this.data) {
        return this.$store.getters.pfmProjectTypeList.find(v => v.id === this.data)
      } else {
        return null
      }
    }
  },
  methods: {
    clearData () {
      this.$emit('input', null)
      this.$emit('change', null)
    },
    onPick (data) {
      this.$emit('input', data.id)
      this.$emit('change', data)
      this.status.drawer = false
    }
  },
  mounted () {
    this.$store.dispatch('loadPfmProjectTypeList')
  }
}
</script>