<style lang="less" scoped>
  .position-page {
    display: flex;
    flex-direction: column;
  }
</style>

<style>
  .project-type-tree-render .ivu-tree-title {
    width: 100%;
  }
  .project-type-tree-render .ivu-tag {
    border: none !important;
  }
</style>

<template>
  <div class="position-page">
    <Poptip v-if="$authFunsProxyNoRoute['performance.project-typeManage']" confirm @on-ok="dataSync" title="确定同步吗？">
      <Button :loading="loading.sync" type="warning" icon="md-sync">数据同步</Button>
    </Poptip>
    <div style="flex: 1;height: 0;overflow: auto;" v-loadingx="loading.load">
      <template v-if="status.form">
        <fm-title :title-text="(formData.id ? '修改' : '新增') + '绩效项目类型'"></fm-title>
        <fm-form label-alone label-align="left">
          <fm-form-item label="名称">
            <fm-input-new v-model="formData.name" placeholder="请输入名称"/>
          </fm-form-item>
          <fm-form-item label="his编码">
            <fm-input-new v-model="formData.hisCode" placeholder="请输入his编码"/>
          </fm-form-item>
          <fm-form-item label="类型">
            <Select placeholder="请选择类型" clearable v-model="formData.type">
              <Option v-for="item in pfmProjectTypeCategory" :key="item.value" :value="item.value">{{item.label}}</Option>
            </Select>
          </fm-form-item>
          <fm-form-item label="上级类型">
            <Select placeholder="请选择上级类型" filterable clearable v-model="formData.pId">
              <Option v-for="item in dataList" :key="item.id" :value="item.id">{{item.name}}</Option>
            </Select>
          </fm-form-item>
          <fm-form-item label="普通工作量绩效">
            <fm-switch size="small" v-model="formData.isOutUnit">
              <span slot="on">不纳入</span>
              <span slot="off">纳入</span>
            </fm-switch>
          </fm-form-item>
        </fm-form>
      </template>
      <template v-else>
        <Tree :select-node="false" expand-node :data="dataTree" :render="renderContent" class="project-type-tree-render"></Tree>
      </template>
    </div>
    <template v-if="!picker">
      <div v-if="status.form" style="margin-top: 10px;display: flex;">
        <Button :loading="loading.submit" style="flex: 1;" @click="status.form = false" type="default" long>取消</Button>
        <Button :loading="loading.submit" style="flex: 1;margin-left: 10px;" @click="submit" type="info" long>确定</Button>
      </div>
      <div v-else-if="$authFunsProxyNoRoute['performance.project-typeManage']" style="margin-top: 10px;">
        <Button @click="addClick" type="info" long>新增绩效项目类型</Button>
      </div>
    </template>
  </div>
</template>

<script>
import { request } from '@/api'

function getDefaultFormData () {
  return {
    id: null,
    name: null,
    hisCode: null,
    type: null,
    pId: null,
    isOutUnit: null,
  }
}

function treeRecursion(data, pid, pidKey = 'pid', idKey = 'id', item = null, deep = 0, endShow = false, children = 'children') {
    let tree = [];
    for (let row of data) {
        if (row[pidKey] == pid) {
            let sub = treeRecursion(data, row[idKey], pidKey, idKey, item, deep + 1, endShow, children);
            if (endShow || sub.length) {
                row[children] = sub;
            }
            if (typeof item === 'function') {
              tree.push(item(row));
            } else {
              tree.push(row);
            }
        }
    }
    return tree;
}

export default {
  props: {
    picker: { type: Boolean, default: false }
  },
  data () {
    return {
      dataList: [],
      formData: getDefaultFormData(),
      status: {
        form: false
      },
      loading: {
        submit: false,
        load: false,
        sync: false
      }
    }
  },
  computed: {
    dataTree () {
      const dataList = this.dataList
      return treeRecursion(JSON.parse(JSON.stringify(dataList)), null, 'pId', 'id', (item) => {
        return {
          data: item,
          expand: true,
          children: item.children || []
        }
      })
    },
    pfmProjectTypeCategory () {
      return this.$store.getters.pfmProjectTypeCategory
    }
  },
  methods: {
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_project_type/sync', 'post')
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    renderContent (h, { data }) {
      const actions = this.picker ? [
        h('Button', {
          props: {
            type: 'default',
            size: 'small'
          },
          on: {
            click: (e) => {
              e.stopPropagation()
              this.$emit('pick', data.data)
            }
          }
        }, '选择')
      ] : (this.$authFunsProxyNoRoute['performance.project-typeManage'] ? [
        h('Button', {
          props: {
            type: 'default',
            size: 'small',
          },
          style: {
            marginRight: '8px'
          },
          on: {
            click: (e) => {
              e.stopPropagation()
              this.itemEdit(data.data)
            }
          }
        }, '修改'),
        h('Button', {
          props: {
            type: 'default',
            size: 'small',
          },
          on: {
            click: (e) => {
              e.stopPropagation()
              this.itemDel(data.data)
            }
          }
        }, '删除')
      ] : [])
      return h('div', {
        style: {
          padding: '10px 32px 10px 0',
          borderBottom: '1px solid #EEE'
        }
      }, [
        h('div', {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
          }
        }, [
          h('div', {}, [
            h('span', {
              style: { fontSize: '14px', fontWeight: 800, color: '#000' }
            }, data.data.name),
            h('span', {
              style: { fontSize: '14px', color: '#657180', marginLeft: '20px' }
            }, data.data.hisCode),
          ]),
          h('div', {}, actions)
        ]),
        h('div', {
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px'
          }
        }, [
          h('span', '类型: ' + data.data.typeText),
          h('Tag', {
            props: {
              color: data.data.isOutUnit ? 'orange' : 'blue'
            }
          }, (data.data.isOutUnit ? '不纳入' : '纳入') + '大循环绩效')
        ])
      ])
    },
    async loadData () {
      if (this.$authFunsProxyNoRoute['performance.project-typeGet']) {
        this.loading.load = true
        const dataList = await this.$store.dispatch('loadPfmProjectTypeList', true)
        const pfmProjectTypeCategory = this.pfmProjectTypeCategory
        dataList.forEach(item => {
          const cate = pfmProjectTypeCategory.find(v => v.value === item.type)
          item.typeText = cate ? cate.label : item.type
        })
        this.dataList = dataList
        this.loading.load = false
      }
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.form = true
    },
    async itemDel (item) {
      const confirm = await this.$dialog.confirm('确定删除吗？')
      if (confirm) {
        await request('/performance/pfm_project_type/' + item.id, 'delete')
        this.$Message.success('已删除')
        this.$emit('refresh')
        this.loadData()
      }
    },
    async submit () {
      try {
        this.loading.submit = true
        if (this.formData.id) {
          await request('/performance/pfm_project_type/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/performance/pfm_project_type', 'post', this.formData)
        }
        this.status.form = false
        this.$Message.success('操作成功')
        this.loadData()
        this.loading.submit = false
      } catch (e) {
        this.loading.submit = false
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>